import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  pageTitle: 'Project Manager',
  introTitle: 'Project Manager (m/f/d) - Software Development in full-time or project-based',
  introInfo: '<p>freshcells systems engineering GmbH is a software company based in Düsseldorf, Germany with a dynamic team of over 40 employees.</p><p>We do not have a hire-and-fire mentality; we prefer to lean back with cold drinks after work.</p><p><b>We are looking for a full-time or project-based Project Manager for Software Development to support our team in Düsseldorf.</b></p><p>Our Project Managers cover a broad spectrum of responsibilities. These include operative duties (sprint planning, working with Jira, testing, invoicing) as well as complex management tasks: You are responsible for stakeholder management, project monitoring and controlling, requirements management, leading international teams, and many more exciting tasks. It is our project managers\' versatility and their combination of strong social skills with a high technological know-how that sets us apart from the crowd.</p>',
  responsibilitiesTitle: 'Your Responsibilities',
  responsibility1: 'You support companies from a wide range of industries whose e-commerce platforms we develop and maintain.',
  responsibility2: 'You are our interface to the customer. You receive and handle requests, whether they’re bug reports or feature requests.',
  responsibility3: 'You work on concepts with your customers and ensure that the requirements for the design and development team are intelligible and divided into realistic work packages.',
  responsibility4: 'You advise, you are available, and you support. In short, you are your customers’ most important point of contact. You know the applications. So, it’s up to you to ask: What could we do even better? How can we help our customers be even more successful? Bring your ideas.',
  responsibility5: 'You’ll manage requests and budgets with JIRA. Ideally, you’ll have already worked with it. If not, you should have experience with similar software tools.',
  qualificationsTitle: 'Your Qualifications',
  qualification1: 'At least three years\' provable experience as a Project Manager in Software Development',
  qualification2: 'Experience in leading developer teams, an open personality, and excellent communication skills',
  qualification3: 'A gift for organization combined with analytical skills – you create clear structures and deliver targeted analyses',
  qualification5: 'Solid technological know-how',
  qualification6: 'Practical experience in agile Project Management',
  qualification7: 'Elevated sense of responsibility',
  qualification8: 'Business fluent in written and spoken German and English'
})
