import React, { useEffect } from "react"
import { navigate, useIntl } from "gatsby-plugin-intl"
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import SeniorProjectManagerPage from '../../../components/VacancyPage/SeniorProjectManagerPage'
import messages from '../../../messages/pageTitles.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'
import SEO from '../../../components/seo'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  useEffect(() => {
    if (global.window && locale === 'en') {
      navigate('/career/')
    }
  }, [])

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleSeniorProjectManager)}
        description={formatMessage(metaDescription.seniorProjectManagerDescription)}
        lang={locale}
        nofollow={locale === 'en'}
      />
      <SeniorProjectManagerPage />
    </Layout>
  )
}

export default IndexPage
